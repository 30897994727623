import { useFormik } from "formik";
import * as Yup from "yup";
import { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import {
  emailRegExp,
  getAge,
  getFormatedDate,
  phoneRegExp,
} from "../../../../utils/functions/table";
import {
  useCreateProfileBasicInfoMutation,
  useOnChangeValidationMutation,
} from "../../../../store/queries/Registration";
import {
  getUserDetails,
  updateFormData,
} from "../../../../store/slices/Registration/createProfileSlice";
import { useNavigate } from "react-router-dom";
import { profile } from "../../../../store/queries/Profile";
import { useSearchParams } from "react-router-dom/dist";
import countryData from "../../../../utils/components/countryCode";

const useBasic = ({
  optionsData,
  isOptionDataSuccess,
  activeUser,
  toTab,
  edit,
  webId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saveAndNext, setSaveAndNext] = useState(false);
  const { currentFormDataBasic } = useSelector((state) => state.createProfile);
  const casteRef = useRef("");
  const educationRef = useRef("");
  const jobRef = useRef("");
  const districtRef = useRef("");
  const stateRef = useRef("");
  const [errors, setErrors] = useState("");
  const [onChangeRegisterIdResponse, setOnChangeRegisterIdResponse] = useState(
    {}
  );
  const [onChangeEmailResponse, setOnChangeEmailResponse] = useState({});
  const [onChangeMobResponse, setOnChangeMobResponse] = useState({});
  const [onChangeMob2Response, setOnChangeMob2Response] = useState({});
  const [onChangeWhastappResponse, setOnChangeWhastappResponse] = useState({});

  const [params] = useSearchParams();
  const activeProfile =
    params.get("active") ?? sessionStorage.getItem("active");

  const ids = params.get("id")
    ? [...params.get("id").split(","), activeProfile]
    : [activeProfile];

  const [registerProfile, { isLoading: isUpdating }] =
    useCreateProfileBasicInfoMutation();

  const [validateOnChange] = useOnChangeValidationMutation();

  let createProfileBasicVal = Yup.object({
    profile_rate_score: Yup.number().typeError(
      "profile rate score must be a number"
    ),
    last_boosted: edit && Yup.string(),
    register_number: Yup.string().required("*Required"),
    register_package_id: Yup.string().required("*Required"),
    branch_id: Yup.string().required("*Required"),
    name: Yup.string().required("*Required"),
    email: Yup.string().matches(emailRegExp, "Enter a valid email"),
    cc_mobile1: Yup.string().required("Country code required"),
    mobile: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(9, "phone number must be 9 digits")
      .max(13, "phone number must be 13 digits")
      .required("*Required"),
    cc_mobile2: Yup.string().required("Country code required"),
    phone_no: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(9, "phone number must be 9 digits")
      .max(13, "phone number must be 13 digits"),
    cc_whatsapp: Yup.string().required("Country code required"),
    whatsapp_no: Yup.string()
      .matches(phoneRegExp, "Enter a valid phone number")
      .min(9, "phone number must be 9 digits")
      .max(13, "phone number must be 13 digits"),
    gender: Yup.string().required("*Required"),
    date_of_birth: Yup.string().required("*Required"),

    payment_date: Yup.string().test(
      "Payment date",
      "Payment date is required",
      function (value) {
        // const { register_package_id } = this.parent;
        if (
          formik?.values?.register_package_id === 1 ||
          formik?.values?.register_package_id === 2
        ) {
          return value ? false : true;
        }
        return value ? true : false;
      }
    ),

    marital_status: Yup.string().required("*Required"),
    religion: Yup.string().required("*Required"),
    caste: Yup.string().required("*Required"),

    height: Yup.string().required("*Required"),
    complexion: Yup.string().required("*Required"),
    body_type: Yup.string().required("*Required"),
    special_case: Yup.string().required("*Required"),
    education_main_category: Yup.string().required("*Required"),
    job_main_category: Yup.string().required("*Required"),
    educational_category_id: Yup.string().required("*Required"),
    job_category_id: Yup.string().required("*Required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      branch_id: "",
      profile_rate_score_id: false,
      last_boosted: "",
      profile_visibility_score: "",
      profile_rate_score: "",
      register_package_id: "",
      profile_created_for: "",
      register_number: "",
      phone_no: "",
      whatsapp_no: "",
      gender: "",
      date_of_birth: "",
      payment_date: null,
      age: "",
      religion: "",
      caste: "",
      sub_caste: "",
      height: "",
      marital_status: "",
      no_of_children: "",
      complexion: "",
      body_type: "",
      special_case: "",
      special_case_details: "",
      annual_income: "",
      education_main_category: "",
      educational_category_id: "",
      education_info: "",
      job_main_category: "",
      job_category_id: "",
      job_info: "",
      job_country: "1",
      job_state: "",
      job_district: "",
      location_address: "",
      //country codes of phone numbers
      cc_mobile1: "+91",
      cc_mobile2: "+91",
      cc_whatsapp: "+91",
    },

    validationSchema: edit !== "true" ? createProfileBasicVal : null,
    enableReinitialize: true,
    onSubmit: (values) => {
      try {
        let obj = {
          ...values,
          user_id: activeUser,
          date_of_birth: getFormatedDate(values.date_of_birth),
          cc_mobile1: values?.cc_mobile1 ?? "+91",
          cc_mobile2: values?.cc_mobile2 ?? "+91",
          cc_whatsapp: values?.cc_whatsapp ?? "+91",
        };
        if (values?.payment_date) {
          obj = { ...obj, payment_date: getFormatedDate(values?.payment_date) };
        }
        dispatch(
          updateFormData((state) => {
            state.currentFormDataBasic = {
              ...state.currentFormDataBasic,
              ...obj,
            };

            state.completedTabs = {
              ...state.completedTabs,
              Basic: true,
              Family: true,
            };
          })
        );

        registerProfile(obj).then((response) => {
          formik.setErrors("");
          setErrors("");
          if (response?.data?.status_code === 200) {
            submitForm(
              response?.data?.data?.user_id,
              response?.data?.data?.web_id
            );
          } else if (response?.error?.data?.status_code === 422) {
            formik.setErrors(response?.error?.data?.errors);
          }
        });
      } catch (error) {
        setErrors("Something went wrong");
      }
    },
  });

  const submitForm = (userId, webId) => {
    dispatch(profile.util.invalidateTags(["Profile", "Overview"]));

    if (edit !== "true") {
      navigate({ search: `user=${userId}` });
    }

    dispatch(
      updateFormData((state) => {
        state.activeTab = "Family";
        state.webId = webId;
        state.currentFormDataBasic = {
          ...state.currentFormDataBasic,
          user_id: userId,
        };
      })
    );

    toast.success(
      activeUser ? "Successfully updated" : "Successfully submitted"
    );

    if (toTab && !saveAndNext) {
      navigate(-1);
    }
  };
  useEffect(() => {
    if (Object.keys(currentFormDataBasic).length === 0 && edit === "true") {
      dispatch(
        getUserDetails({
          endpoint: "user-basic-details",
          id: activeUser,
        })
      );
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (isOptionDataSuccess) {
      Object.keys(currentFormDataBasic || {}).forEach((key) => {
        formik.setFieldValue(key, currentFormDataBasic?.[key]);
      });
    }
    // eslint-disable-next-line
  }, [isOptionDataSuccess, currentFormDataBasic]);

  useEffect(() => {
    let totalFields = Object.values(formik.values).filter(
      (f) => f !== "" && f !== null && f !== false
    ).length;

    dispatch(
      updateFormData((state) => {
        state.CompletedPercentage = {
          ...state.CompletedPercentage,
          Basic: Math.ceil(
            (totalFields / Object.keys(formik.values).length) * 100
          ),
        };
        state.webId = webId;
      })
    );
    // eslint-disable-next-line
  }, [formik.values]);

  const handleCheckbox = (isChecked) => {
    formik.setFieldValue("profile_rate_score_id", !isChecked);
  };

  const handleSelectReligion = (value) => {
    casteRef.current.setValue([], "clear");
    formik.setFieldValue("religion", value?.id || null);
  };

  const handleSelectEducation = (value) => {
    educationRef.current.setValue([], "clear");
    formik.setFieldValue("education_main_category", value?.id || null);
  };
  const handleSelectJob = (value) => {
    jobRef.current.setValue([], "clear");
    formik.setFieldValue("job_main_category", value?.id || null);
  };
  const handleSelectJobCountry = (value) => {
    stateRef.current.setValue([], "clear");
    districtRef.current.setValue([], "clear");
    formik.setFieldValue("job_country", value?.id || null);
  };
  const handleSelectJobState = (value) => {
    districtRef.current.setValue([], "clear");
    formik.setFieldValue("job_state", value?.id || null);
  };

  const handleDobChange = (e) => {
    formik.setFieldValue(
      "date_of_birth",
      format(new Date(e.target.value), "yyyy-MM-dd")
    );
    formik.setFieldValue("age", getAge(getFormatedDate(e.target.value)));
    //formik.setFieldValue("date_of_birth", value);
  };

  // const handlePaymentDateChange = (e) => {
  //   formik.setFieldValue("payment_date", e?.target?.value);
  // };

  const handlePaymentDateChange = (value) => {
    console.log(value, "inside");
    formik?.setFieldValue("payment_date", getFormatedDate(value) ?? null);
  };

  const handleClearTimeValues = () => {
    formik.setFieldValue("payment_date", null);
  };

  const handleAgeChange = (e) => {
    let newDate = new Date();
    newDate.setFullYear(newDate.getFullYear() - e.target.value);
    newDate.setMonth(0);
    newDate.setDate(1);
    formik.setFieldValue("date_of_birth", newDate.toLocaleDateString("fr-ca"));
    formik.setFieldValue("age", e.target.value);
  };

  const handleRegisterNumberBlur = () => {
    let obj = {
      user_id: parseInt(activeUser),
      mobile_number: formik.values.register_number,
      type: 5,
    };

    validateOnChange(obj).then((response) => {
      setErrors("");

      if (response?.data?.status_code === 200) {
        setOnChangeRegisterIdResponse(response?.data?.data);
        //formik.setErrors(response?.data?.data);
      }
      // else if (response?.error?.data?.status_code === 422) {
      //   //formik.setErrors(response?.data?.data);
      // }
      setOnChangeRegisterIdResponse({});
    });
  };

  const handleMobileBlur = () => {
    let obj = {
      user_id: parseInt(activeUser),
      mobile_number: formik.values.mobile,
      type: 1,
    };

    validateOnChange(obj).then((response) => {
      setErrors("");

      if (response?.data?.status_code === 200) {
        setOnChangeMobResponse(response?.data?.data);
        //formik.setErrors(response?.data?.data);
      }
      // else if (response?.error?.data?.status_code === 422) {
      //   // formik.setErrors(response?.error?.data?.errors);
      // }
      else {
        setOnChangeMobResponse({});
      }
    });
  };

  const handleMobile2Blur = () => {
    let obj = {
      user_id: parseInt(activeUser),
      mobile_number: formik.values.phone_no,
      type: 2,
    };

    validateOnChange(obj).then((response) => {
      setErrors("");

      if (response?.data?.status_code === 200) {
        setOnChangeMob2Response(response?.data?.data);
        //formik.setErrors(response?.data?.data);
      }
      // else if (response?.error?.data?.status_code === 404) {
      //   //formik.setErrors(response?.error?.data?.errors);
      //   // setOnChangeMob2Response("")
      // }
      else {
        setOnChangeMob2Response({});
      }
    });
  };

  const handleWhatsappNoBlur = () => {
    let obj = {
      user_id: parseInt(activeUser),
      mobile_number: formik.values.whatsapp_no,
      type: 3,
    };

    validateOnChange(obj).then((response) => {
      setErrors("");

      if (response?.data?.status_code === 200) {
        setOnChangeWhastappResponse(response?.data?.data);
        //formik.setErrors(response?.data?.data);
      }
      // else if (response?.error?.data?.status_code === 422) {
      //   //formik.setErrors(response?.error?.data?.errors);
      // }
      else {
        setOnChangeWhastappResponse({});
      }
    });
  };

  const handleEmailBlur = () => {
    let obj = {
      user_id: parseInt(activeUser),
      mobile_number: formik.values.email,
      type: 4,
    };

    validateOnChange(obj).then((response) => {
      setErrors("");

      if (response?.data?.status_code === 200) {
        setOnChangeEmailResponse(response?.data?.data);
        //formik.setErrors(response?.data?.data);
      }
      // else if (response?.error?.data?.status_code === 422) {
      //   //formik.setErrors(response?.error?.data?.errors);
      // }
      else {
        setOnChangeEmailResponse({});
      }
    });
  };

  const handleProfileRedirect = (user_id) => {
    const newIds = ids.filter((id) => id !== user_id).join(",");
    window.open(
      `${window.location.origin}/${`profile?active=${user_id}&id=${newIds}`}`
    );
  };

  const handleLastBoostedDate = (e) => {
    formik.setFieldValue(
      "last_boosted",
      format(new Date(e.target.value), "yyyy-MM-dd")
    );
  };

  return {
    currentFormDataBasic,
    errors,
    formik,
    casteRef,
    jobRef,
    educationRef,
    stateRef,
    districtRef,
    isUpdating,
    onChangeMob2Response,
    onChangeMobResponse,
    onChangeWhastappResponse,
    onChangeRegisterIdResponse,
    onChangeEmailResponse,
    countryData,
    handleClearTimeValues,
    setSaveAndNext,
    handleProfileRedirect,
    handleRegisterNumberBlur,
    handleMobileBlur,
    handleMobile2Blur,
    handleWhatsappNoBlur,
    handleEmailBlur,
    handleAgeChange,
    handleCheckbox,
    handleSelectReligion,
    handleSelectEducation,
    handleSelectJob,
    handleSelectJobCountry,
    handleSelectJobState,
    handleDobChange,
    handlePaymentDateChange,
    handleLastBoostedDate,
  };
};

export default useBasic;
