import React from "react";
import Select from "react-select";
import { Input } from "@wac-ui-dashboard/wac_component_library";
import { DatePicker } from "rsuite";

const RowTwo = ({
  formik,
  optionsData,
  handleSelectReligion,
  onChangeWhastappResponse,
  currentFormDataBasic,
  handleAgeChange,
  handleWhatsappNoBlur,
  handleProfileRedirect,
  casteRef,
  handleDobChange,
  handlePaymentDateChange,
  countryData,
  handleClearTimeValues,
}) => {
  let newDate = new Date();
  newDate.setFullYear(newDate.getFullYear() - 18);
  console.log(formik?.values?.payment_date, "formik?.values?.payment_date");
  return (
    <>
      <div className={`col-3`}>
        <div className={`input-wrap pro-mb-4 `}>
          <div className="input-country-wrap ">
            <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
              Whatsapp No
            </label>
            <div className="input-main-wrap">
              <div className="code-wrap pro-mb-4 ">
                <Select
                  id="cc_whatsapp"
                  options={countryData}
                  getOptionLabel={(option) => option.label}
                  getOptionValue={(option) => option.value}
                  value={countryData?.filter(
                    (item) => item?.value === formik?.values?.cc_whatsapp
                  )}
                  classNamePrefix="pro-input"
                  className="country-select"
                  onBlur={formik.handleBlur("cc_whatsapp")}
                  menuPlacement="auto"
                  onChange={(value) =>
                    formik.setFieldValue("cc_whatsapp", value?.value)
                  }
                ></Select>
              </div>
              <input
                // label={"Whatsapp No"}
                type="text"
                id="whatsapp_no"
                name="whatsapp_no"
                className={`pro-input lg ${
                  formik.touched.whatsapp_no &&
                  formik.errors.whatsapp_no &&
                  " error"
                }`}
                {...formik.getFieldProps("whatsapp_no")}
                onBlur={(e) => handleWhatsappNoBlur(e)}
                error={formik.touched.whatsapp_no && formik.errors.whatsapp_no}
                errorMessage={formik.errors.whatsapp_no}
              />
              <span>
                {Object.keys(onChangeWhastappResponse)?.length > 0 ? (
                  <span
                    className={`erro-web-id`}
                    onClick={() =>
                      handleProfileRedirect(onChangeWhastappResponse?.user_id)
                    }
                  >
                    <span className="err">
                      {onChangeWhastappResponse?.whatsapp_no}
                    </span>
                    <span className={`web-id`}>
                      <span>Register ID :</span>{" "}
                      {onChangeWhastappResponse?.web_id}
                    </span>
                  </span>
                ) : (
                  <></>
                )}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Gender*
          </label>
          <Select
            id="gender"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.genderList}
            getOptionLabel={(option) => `${option.gender_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.gender && formik.errors.gender && " error"
            }`}
            value={optionsData?.data?.genderList?.filter(
              (p) => p.id?.toString() === formik.values.gender?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("gender")}
            onChange={(value) =>
              formik.setFieldValue("gender", value?.id || null)
            }
          ></Select>
          {formik.touched.gender && formik.errors.gender && (
            <span className="error-text">{formik.errors.gender}</span>
          )}
        </div>
      </div>
      <div className={`col-3 fill-width`}>
        <div className="input-wrap pro-mb-5 ">
          <div className={`pro-w-100 pro-d-flex`}>
            <Input
              value={formik.values.date_of_birth}
              label={" Date Of Birth*"}
              type={"date"}
              max={newDate.toLocaleDateString("fr-ca")}
              className={`pro-input lg ${
                formik.touched.date_of_birth &&
                formik.errors.date_of_birth &&
                "error"
              }`}
              onChange={(e) => {
                handleDobChange(e);
              }}
              onBlur={formik.handleBlur("date_of_birth")}
              error={
                formik.touched.date_of_birth && formik.errors.date_of_birth
              }
              errorMessage={formik.errors.date_of_birth}
            />
          </div>
        </div>
      </div>
      <div className={`col-3`}>
        <Input
          label={"Age*"}
          type="text"
          id="age"
          name="age"
          className={`pro-input lg ${
            formik.touched.age && formik.errors.age && " error"
          } `}
          value={formik.values.age}
          error={formik.touched.age && formik.errors.age}
          onChange={(e) => handleAgeChange(e)}
          onBlur={formik.handleBlur("age")}
          // {...formik.getFieldProps("age")}
          errorMessage={formik.errors.age}
        />
      </div>
      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Marital Status*
          </label>
          <Select
            id="marital_status"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.maritalStatusList}
            getOptionLabel={(option) => `${option.marital_status}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.marital_status &&
              formik.errors.marital_status &&
              " error"
            }`}
            value={optionsData?.data?.maritalStatusList?.filter(
              (p) =>
                p.id?.toString() === formik.values.marital_status?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("marital_status")}
            onChange={(value) =>
              formik.setFieldValue("marital_status", value?.id || null)
            }
          ></Select>
          {formik.touched.marital_status && formik.errors.marital_status && (
            <span className="error-text">{formik.errors.marital_status}</span>
          )}
        </div>
      </div>
      <div className={`col-3`}>
        <Input
          label={"No Of Children"}
          type="text"
          id="no_of_children"
          name="no_of_children"
          className={`pro-input lg ${
            formik.touched.no_of_children &&
            formik.errors.no_of_children &&
            " error"
          }`}
          {...formik.getFieldProps("no_of_children")}
          error={formik.touched.no_of_children && formik.errors.no_of_children}
          errorMessage={formik.errors.no_of_children}
        />
      </div>
      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Religion*
          </label>
          <Select
            id="religion"
            placeholder={"Select"}
            isClearable={true}
            options={optionsData?.data?.religionList}
            getOptionLabel={(option) => `${option.religion_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.religion && formik.errors.religion && " error"
            }`}
            value={optionsData?.data?.religionList?.filter(
              (p) => p.id?.toString() === formik.values.religion?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("religion")}
            onChange={(value) => handleSelectReligion(value)}
          ></Select>
          {formik.touched.religion && formik.errors.religion && (
            <span className="error-text">{formik.errors.religion}</span>
          )}
        </div>
      </div>
      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Caste*
          </label>
          <Select
            id="caste"
            placeholder={"Select"}
            ref={casteRef}
            isClearable={true}
            options={optionsData?.data?.casteList?.filter(
              (p) =>
                p.religion_id?.toString() === formik.values.religion?.toString()
            )}
            getOptionLabel={(option) => `${option.caste_name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input lg multi-select ${
              formik.touched.caste && formik.errors.caste && " error"
            } ${!formik.values.religion && "disabled"}`}
            value={optionsData?.data?.casteList?.filter(
              (p) => p.id?.toString() === formik.values.caste?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("caste")}
            onChange={(value) =>
              formik.setFieldValue("caste", value?.id || null)
            }
          ></Select>
          {formik.touched.caste && formik.errors.caste && (
            <span className="error-text">{formik.errors.caste}</span>
          )}
        </div>
      </div>
      <div className={`col-3`}>
        <Input
          label={"Sub Caste (If Any)"}
          type="text"
          id="sub_caste"
          name="sub_caste"
          className={`pro-input lg ${
            formik.touched.sub_caste && formik.errors.sub_caste && " error"
          }`}
          {...formik.getFieldProps("sub_caste")}
          error={formik.touched.sub_caste && formik.errors.sub_caste}
          errorMessage={formik.errors.sub_caste}
        />
      </div>
      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Profile Created For
          </label>
          <Select
            id="profile_created_for"
            placeholder={"Select"}
            isClearable={true}
            isDisabled={formik?.values?.gender === ""}
            options={optionsData?.data?.profileCreatedForList?.filter(
              (p) =>
                p?.gender?.toLowerCase() ===
                  formik?.values?.gender?.toLowerCase() || p?.gender === "both"
            )}
            getOptionLabel={(option) => `${option.name}`}
            getOptionValue={(option) => `${option["id"]}`}
            className={`pro-input  lg multi-select ${
              formik.touched.profile_created_for &&
              formik.errors.profile_created_for &&
              " error"
            }`}
            value={optionsData?.data?.profileCreatedForList?.filter(
              (p) =>
                p.id?.toString() ===
                formik.values.profile_created_for?.toString()
            )}
            classNamePrefix="pro-input"
            onBlur={formik.handleBlur("profile_created_for")}
            onChange={(value) =>
              formik.setFieldValue("profile_created_for", value?.id || null)
            }
          ></Select>
          {formik.touched.profile_created_for &&
            formik.errors.profile_created_for && (
              <span className="error-text">
                {formik.errors.profile_created_for}
              </span>
            )}
        </div>
      </div>

      <div className={`col-3`}>
        <div className="input-wrap pro-mb-5">
          <label htmlFor="" className="pro-font-sm pro-mb-1 pro-fw-medium">
            Payment Date
          </label>
          <DatePicker
            showMeridian={false}
            defaultValue={null}
            oneTap={true}
            menuClassName="time-picker"
            format="dd-MM-yyyy"
            placeholder="dd/mm/yyyy"
            size="md"
            cleanable={true}
            // showHeader={false}
            onChange={(value) => handlePaymentDateChange(value)}
            value={
              formik?.values?.payment_date
                ? new Date(formik?.values?.payment_date)
                : null
            }
            onClean={() => handleClearTimeValues()}
            editable={false}
          />
          {formik.touched.payment_date && formik.errors.payment_date && (
            <span className="error-text">` `{formik.errors.payment_date}</span>
          )}
        </div>
      </div>
    </>
  );
};

export default RowTwo;
